import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("textarea", {
      ref: "blueprintStringTextArea",
      readonly: "",
      class: "blueprint-string",
      rows: "2",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.blueprintString) = $event))
    }, null, 512), [
      [_vModelText, _ctx.blueprintString]
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyToClipboard && _ctx.copyToClipboard(...args)))
    }, "Copy Blueprint String")
  ], 64))
}