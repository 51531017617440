
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    blueprintString: String
  }
})
export default class BlueprintStringCopier extends Vue {
    blueprintString!: string;

    copyToClipboard() {
        /* Get the text field */
        let copyText = this.$refs.blueprintStringTextArea;

        if (copyText instanceof HTMLTextAreaElement) {
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand("copy");

            copyText.setSelectionRange(0, 0);
        }
    }
}
